<template>
    <div class="layout-topbar">
        <div class="layout-topbar-wrapper">
            <div class="layout-topbar-left">
                <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
                    <i class="pi pi-bars"></i>
                </a>
                <!-- <router-link id="logo-link" class="layout-topbar-logo" to="/">
                    <img :src="'layout/images/logo-' + (topbarTheme === 'dark' ? 'freya-white' : 'freya') + '.svg'" alt="freya-layout"/>
                </router-link> -->
                <!-- <router-link id="logo-link" class="layout-topbar-logo" to="/">
                    <img class="site-logo-2" src="layout/images/site_logo_2_1.png" alt="tradersr logo">
                </router-link> -->

                <router-link id="logo-link" to="/">
                    <img class="site-logo" src="layout/images/site_logo.png" alt="R logo">
                </router-link>
            </div>

            <AppMenu :layoutMode="layoutMode" :sidebarActive="sidebarActive" :sidebarStatic="sidebarStatic" :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" 
                @sidebar-mouse-leave="onSidebarMouseLeave" @sidebar-mouse-over="onSidebarMouseOver" @toggle-menu="onToggleMenu" 
                @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />

            <!-- <div class="layout-topbar-right">
                <ul class="layout-topbar-actions">
                    <li ref="profile" class="topbar-item user-profile" :class="{'active-topmenuitem fadeInDown': topbarUserMenuActive}">
                        <a @click="onTopbarUserMenuClick">
                            <i class="topbar-icon pi pi-user"></i>
                        </a>
                        <ul class="fadeInDown">
                            <li>
                                <a href="#">
                                    <span>Profile</span>
                                </a>
                            </li>
                            <li>
                                <a @click="onConfigButtonClick($event)">
                                    <span>Settings</span>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <span>Messages</span>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <span>Notifications</span>
                                </a>
                            </li>
                        </ul>
                    </li>                    
                    <li ref="search" class="topbar-item search-item" :class="{'active-topmenuitem': searchActive}">
                        <a @click="onRightMenuButtonClick($event)">
                            <i class="topbar-icon pi pi-search"></i>
                        </a>
                    </li>
                </ul>
            </div> -->

            <div class="layout-topbar-right">
                <ul class="layout-topbar-actions">
                    <li ref="profile" class="topbar-item user-profile" :class="{'active-topmenuitem fadeInDown': topbarUserMenuActive}">
                        <a @click="onTopbarUserMenuClick">
                            <i v-if="IsLogin()" class="topbar-icon pi pi-user-plus"></i>
                            <i v-else class="topbar-icon pi pi-user"></i>
                        </a>
                        <ul class="fadeInDown">
                            <li v-if="IsLogin()">
                                <a @click="onLogOut">
                                    <span>ログアウト ({{ getUser() }})</span>
                                </a>
                            </li>
                            <li v-else>
                                <a @click="onLogIn">
                                    <span>ログイン</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import AppMenu from './AppMenu';
export default {
    name: "AppTopbar",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "menu-button-click", "toggle-menu", "right-menubutton-click", 
        "config-button-click", "sidebar-mouse-over", "sidebar-mouse-leave", "topbar-search-toggle", "topbar-search-click", "topbar-search-hide", 
        "topbar-usermenu-click", "update:searchClick"],
    props: {
        searchActive: Boolean,
        searchClick: Boolean,
        topbarItemClick: Boolean,
        topbarUserMenuActive: Boolean,
        topbarUserMenuClick: Boolean,
        activeTopbarItem: String,
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        topbarTheme: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },
    unmounted() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        getUser(){
            return localStorage.getItem("tradersr_email");
        },
        onLogOut(){
            localStorage.removeItem('tradersr_email');
            localStorage.removeItem('tradersr_token');

            this.$router.push({name:'login'});

            console.log('onLogOut()');
        },
        onLogIn(){
            this.$router.push({name:'login'});
            console.log('onLogIn()');
        },
        IsLogin(){
            return localStorage.getItem("tradersr_token");
        },
        onMenuClick(event) {
            this.$emit("menu-click", event);
        },
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
        },
		onRootMenuItemClick(event) {
			this.$emit("root-menuitem-click", event);
		},
        onMenuButtonClick(event) {
            this.$emit("menu-button-click", event);
        },
        onToggleMenu(event) {
            this.$emit("toggle-menu", event);
        },
        onTopbarSearchToggle(event) {
            this.$emit('topbar-search-toggle', event);
            this.onSearchFocus();
        },
        onTopbarSearchClick(event) {
            this.$emit("topbar-search-click", event);
        },
        onInputKeydown(event) {
            const key = event.which;
            
            //escape, tab and enter
            if (key === 27 || key === 9 || key === 13) {
                this.$emit("topbar-search-hide", event);
            }
        },
        onTopbarUserMenuClick(event) {
            this.$emit("topbar-usermenu-click", event)
        },
        onRightMenuButtonClick(event) {
            this.$emit("right-menubutton-click", event);
        },
        onConfigButtonClick(event) {
            this.$emit("config-button-click", event);
        },
        onSidebarMouseOver() {
            this.$emit("sidebar-mouse-over");
        },
        onSidebarMouseLeave() {
            this.$emit("sidebar-mouse-leave");
        }, 
        onSearchFocus() {
            if(window.innerWidth >= 576) {
                this.$refs.desktopInput.$el.focus();
            }
            else {
                this.$nextTick(function() {
                    this.$refs.phoneInput.$el.focus();
                })
            }
        }
    }, 
    components: { 
        AppMenu
    }
};
</script>