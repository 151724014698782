import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        exact: true,
        component: () => import('./views/Home.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Home' }],
            requiresAuth: true
        },
    },
    {
        path: '/login',
        name: 'login',
        exact: true,
        component: () => import('./pages/Login.vue'),
    },
    {
        path: '/company',
        name: 'company',
        exact: true,
        component: () => import('./views/Company.vue'),
        meta: {
            breadcrumb: [{ parent: 'Company', label: 'Company' }],
        },
    },
    {
        path: '/meigaraall',
        name: 'meigaraall',
        exact: true,
        component: () => import('./views/MeigaraAll.vue'),
        meta: {
            breadcrumb: [{ parent: 'MeigaraAll', label: 'MeigaraAll' }],
        },
    },
    {
        path: '/meigarasearch_base',
        name: 'meigarasearch_base',
        exact: true,
        component: () => import('./views/MeigarasearchBase.vue'),
        meta: {
            breadcrumb: [{ parent: 'MeigarasearchBase', label: 'MeigarasearchBase' }],
        },
    },
    {
        path: '/meigarasearch_ext/:code',
        name: 'meigarasearch_ext',
        exact: true,
        component: () => import('./views/MeigarasearchExt.vue'),
        meta: {
            breadcrumb: [{ parent: 'MeigarasearchExt', label: 'MeigarasearchExt' }],
        },
    },
    {
        path: '/meigaracompare',
        name: 'meigaracompare',
        exact: true,
        component: () => import('./views/Meigaracompare.vue'),
        meta: {
            breadcrumb: [{ parent: 'Meigaracompare', label: 'Meigaracompare' }],
        },
    },
    {
        path: '/industrymcnt',
        name: 'industrymcnt',
        exact: true,
        component: () => import('./views/Industrymcnt.vue'),
        meta: {
            breadcrumb: [{ parent: 'Industrymcnt', label: 'Industrymcnt' }],
        },
    },
    {
        path: '/companyprice',
        name: 'companyprice',
        exact: true,
        component: () => import('./views/Companyprice.vue'),
        meta: {
            breadcrumb: [{ parent: 'Companyprice', label: 'Companyprice' }],
        },
    },
    {
        path: '/meigaratrend',
        name: 'meigaratrend',
        exact: true,
        component: () => import('./views/MeigaraTrend.vue'),
        meta: {
            breadcrumb: [{ parent: 'MeigaraTrend', label: 'MeigaraTrend' }],
        },
    },
    {
        path: '/mnews',
        name: 'mnews',
        exact: true,
        component: () => import('./views/MeigaraNews.vue'),
        meta: {
            breadcrumb: [{ parent: 'MeigaraNews', label: 'MeigaraNews' }],
            requiresAuth: true
        },
    },
    {
        path: '/mnews/:code',
        name: 'mnews_code',
        exact: true,
        component: () => import('./views/MeigaraNews.vue'),
        meta: {
            breadcrumb: [{ parent: 'MeigaraNews', label: 'MeigaraNews' }],
            requiresAuth: true
        },
    },
    {
        path: '/newstrend',
        name: 'newstrend',
        exact: true,
        component: () => import('./views/Newstrend.vue'),
        meta: {
            breadcrumb: [{ parent: 'Newstrend', label: 'Newstrend' }],
            requiresAuth: true
        },
    },
    {
        path: '/meigaranews2',
        name: 'meigaranews2',
        exact: true,
        component: () => import('./views/Meigaranews2.vue'),
        meta: {
            breadcrumb: [{ parent: 'Meigaranews2', label: 'Meigaranews2' }],
            requiresAuth: true
        },
    },
    {
        path: '/meigaranews1',
        name: 'meigaranews1',
        exact: true,
        component: () => import('./views/Meigaranews1.vue'),
        meta: {
            breadcrumb: [{ parent: 'Meigaranews1', label: 'Meigaranews1' }],
            requiresAuth: true
        },
    },
    {
        path: '/kelement',
        name: 'kelement',
        exact: true,
        component: () => import('./views/KabuElement.vue'),
        meta: {
            breadcrumb: [{ parent: 'KabuElement', label: 'KabuElement' }],
            requiresAuth: true
        },
    },
    {
        path: '/kelement/:code',
        name: 'kelement_code',
        exact: true,
        component: () => import('./views/KabuElement.vue'),
        meta: {
            breadcrumb: [{ parent: 'KabuElement', label: 'KabuElement' }],
            requiresAuth: true
        },
    },
    {
        path: '/about',
        name: 'about',
        exact: true,
        component: () => import('./views/About.vue'),
        meta: {
            breadcrumb: [{ parent: 'Help', label: 'About' }],
            requiresAuth: true
        },
    },
    {
        path: '/status',
        name: 'status',
        exact: true,
        component: () => import('./views/Status.vue'),
        meta: {
            breadcrumb: [{ parent: 'Maintenance', label: 'Status' }],
            requiresAuth: true
        },
    },
    {
        path: '/themedicupdate',
        name: 'themedicupdate',
        exact: true,
        component: () => import('./views/ThemedicUpdate.vue'),
        meta: {
            breadcrumb: [{ parent: 'Maintenance', label: 'テーマ辞書' }],
            requiresAuth: true
        },
    },
    {
        path: '/themecustomcrud',
        name: 'themecustomcrud',
        exact: true,
        component: () => import('./views/ThemecustomCrud.vue'),
        meta: {
            breadcrumb: [{ parent: 'Maintenance', label: 'テーマカスタム' }],
            requiresAuth: true
        },
    },
    {
        path: '/newsdicupdate',
        name: 'newsdicupdate',
        exact: true,
        component: () => import('./views/NewsdicUpdate.vue'),
        meta: {
            breadcrumb: [{ parent: 'Maintenance', label: 'ニュース辞書' }],
            requiresAuth: true
        },
    },
    {
        path: '/bookmarkscrud/:code',
        name: 'bookmarkscrud',
        exact: true,
        component: () => import('./views/BookmarksCrud.vue'),
        meta: {
            breadcrumb: [{ parent: 'Maintenance', label: 'ブックマーク' }],
            requiresAuth: true
        },
    },
    {
        path: '/wwindexchart',
        name: 'wwindexchart',
        exact: true,
        component: () => import('./views/Wwindexchart.vue'),
        meta: {
            breadcrumb: [{ parent: 'Market Trend', label: '指数チャート' }],
            requiresAuth: true
        },
    },
    {
        path: '/meigarachart/:code',
        name: 'meigarachart',
        exact: true,
        component: () => import('./views/Meigarachart.vue'),
        meta: {
            breadcrumb: [{ parent: 'Market Trend', label: '東証銘柄' }],
            requiresAuth: true
        },
    },
    {
        path: '/mfinder',
        name: 'mfinder',
        exact: true,
        component: () => import('./views/MeigaraFinder.vue'),
        meta: {
            breadcrumb: [{ parent: 'MeigaraFinder', label: 'MeigaraFinder' }],
            requiresAuth: true
        },
    },
    {
        path: '/mexplorer',
        name: 'mexplorer',
        exact: true,
        component: () => import('./views/MeigaraExplorer.vue'),
        meta: {
            breadcrumb: [{ parent: 'MeigaraExplorer', label: 'MeigaraExplorer' }],
            requiresAuth: true
        },
    },
    {
        path: '/mmining',
        name: 'mmining',
        exact: true,
        component: () => import('./views/MeigaraMining.vue'),
        meta: {
            breadcrumb: [{ parent: 'MeigaraMining', label: 'MeigaraMining' }],
            requiresAuth: true
        },
    },
    {
        path: '/job',
        name: 'job',
        exact: true,
        component: () => import('./views/Job.vue'),
        meta: {
            breadcrumb: [{ parent: 'Job', label: 'Job' }],
            requiresAuth: true
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

router.beforeEach((to) => {
    if (to.meta.requiresAuth && localStorage.getItem("tradersr_token") == null) {
        return { name: "login" };
    }
});

export default router;