<template>
    <span />
    <!-- <div class="layout-footer">
        <div class="grid">
            <div class="col-12">
                <div class="footer-bottom">
                    <h4>tradersR</h4>
                    <h6>Copyright Ⓒ tradersR.com</h6>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="grid grid-nogutter justify-content-center mt-2">
        <router-link id="logo-link" to="/">
            <img class="site-logo-2" src="layout/images/site_logo_2_1.png" alt="tradersr logo">
        </router-link>
    </div> -->
    <div class="grid grid-nogutter justify-content-center mb-2">
        <h6>TR Administration</h6>
    </div>

    <ScrollTop />
</template>

<script>
export default {
    name: "AppFooter"
};
</script>

<style lang="scss" scoped>
@import '@/assets/prod/styles/rcommon.scss';
</style>