<template>
    <div class="menu-wrapper" :class="{ 'layout-sidebar-active' : sidebarActive}"
        @click="onMenuClick" @mouseover="onSidebarMouseOver" @mouseleave="onSidebarMouseLeave">
        <div class="sidebar-logo">
            <router-link to="/">
                <img src="layout/images/logo-freya-single.svg" alt="freya-layout">
            </router-link>
            <a class="sidebar-pin" @click="onToggleMenu($event)">
                <span class="pin"></span>
            </a>
        </div>

        <div class="layout-menu-container">
            <AppSubmenu class="layout-menu" :items="menu" :layoutMode="layoutMode" :parentMenuItemActive="true" :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" :root="true" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />
        </div>
    </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";
export default {
    name: "AppMenu",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "toggle-menu", "sidebar-mouse-over", "sidebar-mouse-leave"],
    props: {
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },
    data() {
        return {
            menu: [
                // {label: "ホーム", icon: "pi pi-home", to: "/"},
                // {label: "マーケット", icon: "pi pi-globe", to:"/wwindexchart"},
                {
                    label: "マーケット", icon: "pi pi-globe",
                    items: [
                        {label: "主要指標", icon: "pi pi-sliders-h", to: "/wwindexchart"},
                    ],
                },
                {
                    label: "銘柄インサイト", icon: "pi pi-building",
                    items: [
                        {label: "銘柄情報", icon: "pi pi-book", 
                            items: [
                                {
                                    label: "33業種及び銘柄", to: "/industrymcnt"
                                },
                                {
                                    label: "銘柄及びテーマ", to: "/meigaratrend"
                                },
                                {
                                    label: "比較される銘柄", to: "/meigarasearch_ext/7203"
                                },
                                {
                                    label: "銘柄一覧 (Valid)", to: "/company"
                                },
                                {
                                    label: "銘柄一覧 (All)", to: "/meigaraall"
                                },
                                {
                                    label: "銘柄検索", to: "/meigarasearch_base"
                                },
                                {
                                    label: "株価一覧", to: "/companyprice"
                                },
                                {
                                    label: "銘柄比較", to: "/meigaracompare"
                                },
                            ],
                        },
                        // {label: "銘柄ニュース", icon: "pi pi-map", to: "/mnews"},
                        {label: "ニュース", icon: "pi pi-map", 
                            items: [
                                {
                                    label: "銘柄情報分析", to: "/mnews"
                                },
                                {
                                    label: "集約テーブル", to: "/meigaranews2"
                                },
                                {
                                    label: "個別テーブル", to: "/meigaranews1"
                                },
                                {
                                    label: "トレンド集約", to: "/newstrend"
                                },
                            ]
                        },
                        {label: "トレンド", icon: "pi pi-chart-line", to: "/meigarachart/7203"},
                        {label: "エレメント", icon: "pi pi-box", to: "/kelement"},
                        {label: "ファインダー", icon: "pi pi-search", to: "/mfinder"},
                        {label: "エクスプローラ", icon: "pi pi-search-plus", to: "/mexplorer"},
                        {label: "マイニング", icon: "pi pi-star", to: "/mmining"},
                    ],
                },
                {
                    label: "メンテナンス", icon: "pi pi-briefcase",
                    items: [
                        {label: "ステータス", icon: "pi pi-check-circle", to: "/status"},
                        {label: "テーマ辞書", icon: "pi pi-language", to: "/themedicupdate"},
                        {label: "テーマカスタム", icon: "pi pi-pencil", to: "/themecustomcrud"},
                        {label: "ニュース辞書", icon: "pi pi-thumbs-up", to: "/newsdicupdate"},
                        {label: "ブックマーク", icon: "pi pi-bookmark", to: "/bookmarkscrud/0"},
                        {label: "ジョブ", icon: "pi pi-caret-right", to: "/job"},
                    ],
                },
                // {
                //     label: "ヘルプ", icon: "pi pi-question-circle",
                //     items: [
                //         {label: "About tradersR.com", to: "/about"},
                //         {label: "株エレメント拡張履歴", to: "/"},
                //         {label: "TRナレッジベース", to: "/"},
                //     ],
                // },
            ]
        }
    },
    methods: {
        onSidebarMouseOver() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                this.$emit("sidebar-mouse-over");
            }
        },
        onSidebarMouseLeave() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                setTimeout(() => {
                    this.$emit("sidebar-mouse-leave");
                }, 250);
            }
        },
        onToggleMenu(event) {
            this.$emit("toggle-menu", event);
        },
        onMenuClick(event) {
            this.$emit("menu-click", event);
        },
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
        },
		onRootMenuItemClick(event) {
			this.$emit("root-menuitem-click", event);
		}
    },
    components: { AppSubmenu },
};
</script>